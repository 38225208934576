import styled from 'styled-components';
import { Box } from 'grommet';

import LinesBg from 'assets/images/bg/lines.svg';
import { mq } from 'configure/theme';
import { palette } from 'configure/theme/colors';

export const BgImageBlock = styled.div`
  display: none;
  ${mq[1]} {
    display: block;
    position: absolute;
    right: 0;
    top: 16px;
    width: 55%;
    max-width: 540px;
  }
  ${mq[3]} {
    display: none;
  }
`;

export const BookHolder = styled(Box)`
  h2 {
    max-width: 100%;
  }
  & > img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 100%;
    margin-top: -10%;
    margin-bottom: -10%;
  }
  ${mq[3]} {
    order: 1;
    & > img {
      margin-bottom: 0;
      width: 160%;
      height: auto;
      max-width: 160%;
    }
  }
`;

export const CloudRoot = styled(Box)`
  background: white;
  ${mq[1]} {
    background: linear-gradient(
      180deg,
      ${palette.grayLight} 0%,
      rgba(246, 247, 249, 0.0001) 100%
    );
  }
`;

export const Wrapper = styled(Box)`
  background-repeat: no-repeat;
  background-size: 1200px auto;
  ${mq[1]} {
    background-image: url(${LinesBg});
    background-position: center center;
  }
`;
