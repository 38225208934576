import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Image } from 'grommet';
import styled from 'styled-components';
import ListMarker from 'assets/icons/list-marker.svg';
import CheckMarker from 'assets/icons/blue-check-icon.svg';

const getSize = ({ small, checked }) => {
  if (checked) {
    return 'auto';
  }
  return small ? '30px' : '50px';
};

const Marker = styled(Image)`
  width: ${props => getSize(props)};
  height: ${props => getSize(props)};
`;

function MarkerList(props) {
  const { content, small, checked } = props;
  const edge = small ? 'small' : 'medium';
  const icon = checked ? CheckMarker : ListMarker;
  return (
    <Box
      direction="row"
      align="center"
      pad={{
        bottom: edge,
      }}
      gap={edge}
    >
      <Marker src={icon} checked={checked} small={small} alt="" />
      <Text color="black" weight="bold" size={edge}>
        {content}
      </Text>
    </Box>
  );
}

MarkerList.propTypes = {
  content: PropTypes.string.isRequired,
  small: PropTypes.bool,
  checked: PropTypes.bool,
};

MarkerList.defaultProps = {
  small: false,
  checked: false,
};

export default MarkerList;
