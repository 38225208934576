import React from 'react';
import { Box } from 'grommet';

import { MobileApp, IntegrationPoint, Cloud } from 'components/products';
import { useResponsive } from 'lib/hooks';

export default function Products() {
  const { size, isSmall } = useResponsive();
  return (
    <Box animation="fadeIn">
      <IntegrationPoint isSmall={isSmall} />
      <MobileApp isSmall={isSmall} />
      <Cloud isSmall={isSmall} size={size} />
    </Box>
  );
}
