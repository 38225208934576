import Icon1 from 'assets/icons/about-1.svg';
import Icon2 from 'assets/icons/about-2.svg';
import Icon3 from 'assets/icons/about-3.svg';

export const cards = [
  {
    index: 1,
    img: Icon1,
    text: 'Always challenge the status quo',
  },
  {
    index: 2,
    img: Icon2,
    text: 'Consider the impact of every decision',
  },
  {
    index: 3,
    img: Icon3,
    text: 'Be the standard you want to see',
  },
];

export const content = [
  {
    label: 'MISSION',
    title: `We empower innovators by delivering seamless access to parking`,
    text: `Vend is focused on unifying access to parking facilities through 
    technology. We build a seamless parking experience, a developer-friendly 
    platform, and intelligent business insight tools that bridge the gap 
    between mobility and parking.`,
  },
  {
    label: 'STORY',
    title: `Like any great startup, it all began in a garage`,
    text: `We started out building parking apps and quickly realized how 
    the variety of legacy parking gate systems resulted in an inconsistent 
    user experience and greatly limited the parking industry’s potential. 
    We set out to create a solution that makes parking access frictionless, 
    allowing any company to leverage parking services efficiently. Today, we 
    are the conduit that digitizes parking access for the mobility sector.`,
  },
  {
    label: 'IMPACT',
    title: `Adapting to a post COVID-19 world`,
    text: `Parking ticket dispensers and pay-on-foot stations have the
    potential to expose customers to infectious agents every day. As the world
    continues to recover from the impact of COVID-19, Vend is offering a
    contactless solution that reduces physical touchpoints. The hands-free,
    windows-up parking experience helps instill confidence to return to the
    city quickly and safely. Prepare for the future, go contactless!`,
  },
];
