import styled from 'styled-components';
import { Box, Image } from 'grommet';

import { mq } from 'configure/theme';

const Banner = styled(Box)`
  background: #ffffff;
  border: 4px solid #dbdde8;
  box-shadow: 0px 10px 20px rgba(80, 85, 105, 0.2);
  border-radius: 26px;
  text-align: left;
  & > h2 {
    font-size: 24px;
    font-weight: 700;
    max-width: unset;
    width: 100%;
  }
  ${mq[1]} {
    & > h2,
    p {
      text-align: center;
    }
  }
`;

const StyledAccess = styled(Image)`
  width: 70%;
  height: auto;
  ${mq[1]} {
    width: 100%;
  }
`;

const CarWrapper = styled.div`
  position: relative;
  max-width: 100%;
  width: 60%;
  margin-top: -40px;
  margin-left: -38%;
  & > img {
    display: block;
    width: 100%;
    max-width: 100%;
  }
  ${mq[1]} {
    margin-top: -80px;
    margin-left: 3.5%;
    width: 70%;
  }
`;

const TopWrapper = styled(Box)`
  background: linear-gradient(180deg, #8194aa 0%, #acb8c7 50.88%, #f6f7f9 100%);
`;

const BannerWrapper = styled(Box)`
  background: linear-gradient(180deg, #8194aa 0%, #acb8c7 46%, #ffffff 100%);
`;

const CovidWrapper = styled(Box)`
  background: linear-gradient(225deg, #ff8451 0%, #ffbe7d 100%);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  p {
    font-size: 17px;
    font-weight: 900;
  }
  img {
    display: block;
    max-width: 60px;
  }
  ${mq[1]} {
    padding-left: 48px;
    padding-right: 48px;
    span {
      font-size: 15px;
    }
    img {
      max-width: 64px;
    }
  }
`;

export {
  TopWrapper,
  Banner,
  StyledAccess,
  CarWrapper,
  CovidWrapper,
  BannerWrapper,
};
