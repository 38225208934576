import styled from 'styled-components';
import { Box } from 'grommet';

import { mq } from 'configure/theme';
import { palette } from 'configure/theme/colors';

export const MobileAppWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  & > div {
    position: relative;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 1000%;
    height: 1000%;
    background-color: ${palette.grayLight};
    transform-origin: top left;
    transform: rotate(-27deg);
    @media (min-width: 1700px) {
      transform: rotate(-13.5deg);
    }
  }
`;

export const TriangleCorner = styled.div`
  position: absolute;
  top: 0;
  left: ${props => (props.reverse ? 'unset' : 0)};
  right: ${props => (props.reverse ? 0 : 'unset')};
  width: 15%;
  min-width: 70px;
  background-color: transparent;
  overflow: hidden;
  display: none;
  transform: ${props => (props.reverse ? 'rotateY(180deg)' : 'none')};
  &:before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: 100%;
    right: 0;
    width: 200%;
    height: 200%;
    background-color: ${palette.grayLight};
    transform-origin: bottom right;
    transform: rotate(-45deg);
  }
  ${mq[1]} {
    display: block;
  }
`;
