import styled from 'styled-components';
import { Box } from 'grommet';

import { mq } from 'configure/theme';
import { palette } from 'configure/theme/colors';

export const IconsHolder = styled(Box)`
  width: 100%;
  .info-card {
    max-width: 88px;
  }
  ${mq[1]} {
    width: 104px;
    min-width: 104px;
    padding-top: 90px;
  }
`;

export const SchemeNote = styled(Box)`
  position: static;
  justify-content: center;
  min-width: 100%;
  margin-bottom: 72px;
  ${mq[1]} {
    max-width: 384px;
    min-width: unset;
    position: absolute;
    top: 56%;
    left: calc(100% + 24px);
    margin-bottom: 0;
  }
`;

export const SchemeIconLabel = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  text-align: center;
`;

export const Connector = styled.div`
  background-color: ${palette.grayLight};
  margin: 10px 0 20px;
  width: 10px;
  height: 10px;
  align-self: center;
  border-radius: 10px;
  display: block;
  &:after {
    content: '';
    display: block;
    background-color: ${palette.grayLight};
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-top: 16px;
    ${mq[1]} {
      display: none;
    }
  }
  ${mq[1]} {
    display: block;
    width: 20%;
    height: 8px;
    margin: 0;
  }
`;
