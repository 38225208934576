import React from 'react';
import { Text, Box, Button } from 'grommet';
import { useHistory } from 'react-router-dom';

import { Banner, BannerWrapper } from './Styled';

export default function BannerBlock() {
  const history = useHistory();
  return (
    <BannerWrapper
      pad={{ bottom: 'medium', horizontal: 'medium' }}
      background="light-7"
      align="center"
    >
      <Box width="large" margin="auto">
        <Banner
          margin={{ bottom: 'large' }}
          pad={{ horizontal: 'small', vertical: 'medium' }}
          align="center"
          responsive={false}
        >
          <Text as="h2" margin={{ bottom: 'small', top: 'hair' }} lh={1.3}>
            The easiest way to access parking.
          </Text>
          <Text
            as="p"
            margin={{ bottom: 'small', top: 'none' }}
            size="small"
            lh={1.67}
          >
            Recent advances in technology have accelerated the global shift to
            digital parking transactions facilitated by smartphones and
            connected vehicles. Vend creates the tools to easily embed a
            seamless parking experience anywhere, empowering innovative
            companies to build the future of parking.
          </Text>
          <Button
            color="black"
            plain
            onClick={() => history.push('/products')}
            label={
              <Box
                background="black"
                pad={{ horizontal: 'large', vertical: 'xsmall' }}
                responsive={false}
              >
                <Text color="white" size="small">
                  Learn more
                </Text>
              </Box>
            }
          />
        </Banner>
      </Box>
    </BannerWrapper>
  );
}
