import { reduce, repeat, type, assoc } from 'rambda';

const bp = ['xsmall', 'small', 'medium', 'large', 'xlarge'];

const getColsArr = (acc, val) => {
  return {
    columns: assoc(
      bp[acc.count],
      type(val) === 'Number' ? repeat('auto', val) : val,
      acc.columns
    ),
    count: acc.count + 1,
  };
};

const getColumns = cols => {
  return reduce(getColsArr, { columns: {}, count: 0 }, cols).columns;
};

export default getColumns;
