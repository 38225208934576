import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Image } from 'grommet';

function IconCard(props) {
  const {
    text,
    imgSrc,
    textSize,
    imgWidth,
    imgFill,
    textAlign,
    ...rest
  } = props;
  return (
    <Box {...rest}>
      <Box width={{ max: imgWidth, min: imgWidth }}>
        <Image src={imgSrc} alt="" fit="contain" fill={imgFill} />
      </Box>
      <Box>
        <Text
          size={textSize}
          textAlign={textAlign}
          weight="bold"
          wordBreak="keep-all"
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
}

IconCard.propTypes = {
  text: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  textSize: PropTypes.string,
  imgFill: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  textAlign: PropTypes.string,
  imgWidth: PropTypes.string,
};

IconCard.defaultProps = {
  textSize: 'medium',
  imgFill: false,
  textAlign: 'center',
  imgWidth: 'unset',
};

export default IconCard;
