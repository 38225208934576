import React, { useMemo } from 'react';
import { Box, Text, Heading, Anchor } from 'grommet';

import { AspectBox } from 'components/shared';
import { Gateway, Banner, CovidMessage, CheckedList } from 'components/home';
import { useResponsive } from 'lib/hooks';

const ratios = {
  xlarge: 0.4,
  large: 0.5,
  medium: 0.75,
};

const mail = {
  body: `body=Vend team,
  I would like to request a demo of the Vend solution...`,
  subject: 'subject=Requesting a Demo',
};

export default function Home() {
  const { size, isLarge } = useResponsive();
  const ratio = useMemo(() => {
    const mobile = 0.8;
    return ratios[size] || mobile;
  }, [size]);
  const bg = isLarge ? '/images/home-desktop.png' : '/images/home-img.png';
  return (
    <Box animation="fadeIn">
      <CovidMessage />

      <AspectBox
        imgSrc={bg}
        aspect={ratio}
        bgYPosition="bottom"
        background="#8194aa"
      >
        <Box pad="xlarge" fill>
          <Box
            basis="full"
            justify="start"
            align="start"
            width="large"
            margin={{
              horizontal: 'auto',
            }}
          >
            <Heading
              level="1"
              color="white"
              size="small"
              margin={{
                top: 'small',
              }}
            >
              The future of parking.
            </Heading>
            <Anchor
              large
              label={
                <Box
                  background="white"
                  pad={{ vertical: 'xsmall', horizontal: 'small' }}
                >
                  <Text color="black" size="small" weight="bold">
                    Request a Demo
                  </Text>
                </Box>
              }
              href={`mailto:sales@vendpark.io?${mail.subject}&${mail.body}`}
            />
          </Box>
        </Box>
      </AspectBox>
      <Banner />
      <Gateway size={size} />
      <CheckedList />
    </Box>
  );
}
