import React from 'react';
import { Box } from 'grommet';

import { MarkerList } from '../shared';

const list = [
  'Compatible with any parking gate',
  'Installed in under 45 mins per lane',
  'Hands-free, windows-up experience',
  'No wiring, drilling, or internet required',
];

export default function CheckedList() {
  return (
    <Box pad="large" background="light-7">
      <Box width="large" margin="auto">
        <Box align="start" direction="row-responsive" justify="evenly">
          <Box basis="1/2">
            {list.slice(0, 2).map(item => (
              <MarkerList small content={item} key={item} />
            ))}
          </Box>
          <Box basis="1/2">
            {list.slice(2).map(item => (
              <MarkerList small content={item} key={item} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
