import React from 'react';
import { Text, Heading, Box, Image } from 'grommet';
import PropTypes from 'prop-types';

import { Wrapper, CloudRoot, BgImageBlock, BookHolder } from './Cloud.styled';
import CheckedList from './CheckedList';

const macbookCut = '/images/macbook-sided.png';
const macbook = '/images/macbook-full.png';

function Cloud({ isSmall, size }) {
  const title = (
    <Heading
      level="2"
      textAlign={isSmall ? 'center' : 'start'}
      size="small"
      color="black"
    >
      Unified Cloud
    </Heading>
  );
  return (
    <CloudRoot pad={{ vertical: 'large' }}>
      <Wrapper
        pad={{
          horizontal: 'large',
          bottom: 'xxlarge',
        }}
        position="relative"
      >
        <Box
          direction="row-responsive"
          width="large"
          margin={{ horizontal: 'auto' }}
        >
          {isSmall || size === 'xlarge' ? (
            <BookHolder basis="1/2">
              {isSmall && title}
              <Image src={macbook} alt="" />
            </BookHolder>
          ) : null}
          <Box basis="1/2">
            {!isSmall && title}
            <Text
              as="div"
              textAlign={isSmall ? 'center' : 'start'}
              size="small"
              color="black"
              lh={2}
            >
              Parking facility owners and operators have full control of
              inventory and pricing though Vend’s unified cloud. Transient,
              monthly, and digital third-party parking transactions can be
              managed in one place. Gain insight into how each demand channel is
              performing and optimize the allocation of parking inventory to
              maximize revenue.
            </Text>
          </Box>
        </Box>
        <BgImageBlock>
          <Image src={macbookCut} fill alt="" />
        </BgImageBlock>
        <Box width="large" margin={{ horizontal: 'auto' }}>
          <CheckedList />
        </Box>
      </Wrapper>
    </CloudRoot>
  );
}

Cloud.propTypes = {
  isSmall: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
};

export default Cloud;
