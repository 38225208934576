import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: auto;
    width: 100%;
    display: block;
  }

  .scroll-light {
    overflow-y: auto;
  }

  .scroll-light::-webkit-scrollbar-track {
    border-radius   : 2px;
    background-color: rgba(255,255,255, .3);
  }

  .scroll-light::-webkit-scrollbar {
    width           : 6px;
    background-color: transparent;
  }

  .scroll-light::-webkit-scrollbar-thumb {
    border-radius   : 2px;
    background-color: #777;
  }

  .scroll-dark {
  overflow-y: auto;
  }

  .scroll-dark::-webkit-scrollbar-track {
    border-radius   : 2px;
    background-color: rgba(0,0,0, .25);
  }

  .scroll-dark::-webkit-scrollbar {
    width           : 6px;
    background-color: transparent;
  }

  .scroll-dark::-webkit-scrollbar-thumb {
    border-radius   : 2px;
    background-color: #fefefe;
  }
  button {
    cursor: pointer;
  }
`;

export default GlobalStyle;
