import React from 'react';
import { Box } from 'grommet';

import { MarkerList } from '../shared';

const list = [
  'Monthly Billing',
  'Reporting & Metrics',
  'Multiple Access Levels',
  'Audit Trails',
  'Validation',
  'Rewards & Loyalty',
];

export default function CheckedList() {
  return (
    <Box
      align="start"
      margin={{ bottom: 'small', top: 'xxlarge' }}
      direction="row-responsive"
      justify="evenly"
    >
      <Box basis="1/2">
        {list.slice(0, 3).map(item => (
          <MarkerList checked content={item} key={item} />
        ))}
      </Box>
      <Box basis="1/2">
        {list.slice(3).map(item => (
          <MarkerList checked content={item} key={item} />
        ))}
      </Box>
    </Box>
  );
}
