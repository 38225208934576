import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';
import { Content, Root, Inner } from './Styled';

function Layout(props) {
  const { children } = props;
  const { pathname, hash } = useLocation();

  const main = useRef(null);

  useEffect(() => {
    if (main.current && !hash) {
      main.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname, hash]);
  return (
    <Root>
      <Header />
      <Content fill>
        <Inner ref={main}>
          {children}
          <Footer />
        </Inner>
      </Content>
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
