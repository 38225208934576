import React from 'react';
import { Route } from 'react-router-dom';

import Routing from './configure/routing';
import Public from './routes';

function Setup() {
  return (
    <Routing>
      <Route path="*" component={Public} />
    </Routing>
  );
}

export default Setup;
