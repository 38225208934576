export default {
  box: {
    extend: props => {
      let extraStyles = '';
      if (props.position) {
        extraStyles = `
            ${extraStyles}
            position: ${props.position}
          `;
      }
      return `
          ${extraStyles}
        `;
    },
  },
};
