export default {
  select: {
    options: {
      container: {
        pad: 'xsmall',
      },
      text: {
        size: 'xsmall',
      },
    },
  },
};
