import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

import AspectBox from './AspectBox';

function InfoCard(props) {
  const { children, ratio, elevation, ...rest } = props;
  return (
    <AspectBox aspect={ratio} overflow="visible">
      <Box fill background="white" elevation={elevation} {...rest}>
        {children}
      </Box>
    </AspectBox>
  );
}

InfoCard.propTypes = {
  children: PropTypes.node.isRequired,
  ratio: PropTypes.number,
  elevation: PropTypes.string,
};

InfoCard.defaultProps = {
  ratio: 4 / 5,
  elevation: 'medium',
};

export default InfoCard;
