import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Anchor, Heading } from 'grommet';

import Scheme from './IntegrationScheme';

const mail = {
  body: `body=Vend team,
  I am interested in partnering with Vend...`,
  subject: 'subject=Partnership Request',
};

function IntegrationPoint({ isSmall }) {
  const title = (
    <Heading
      level="2"
      textAlign={isSmall ? 'center' : 'end'}
      size="small"
      color="black"
    >
      Single Integration Point
    </Heading>
  );
  const alignVal = isSmall ? 'center' : 'end';
  return (
    <Box background="white" pad={{ horizontal: 'large', vertical: 'large' }}>
      <Box direction="row-responsive" width="large" margin="auto">
        <Box basis="1/3" align="start">
          {isSmall && (
            <Box fill align="center">
              {title}
            </Box>
          )}
          <Scheme />
        </Box>
        <Box basis="2/3">
          <Box
            width="large"
            alignSelf="end"
            pad={{
              left: isSmall ? 'none' : 'xlarge',
            }}
          >
            {!isSmall && title}
            <Text
              as="div"
              textAlign={alignVal}
              size="small"
              color="black"
              lh={2}
            >
              The Vend SDK and API provide a single integration point to access
              any parking facility using Bluetooth. With just a few lines of
              code, mobile apps and connected vehicles can easily embed Vend’s
              seamless and secure parking experience.
            </Text>
            <Box alignSelf={alignVal} margin={{ vertical: 'medium' }}>
              <Anchor
                large
                label={
                  <Box
                    background="black"
                    pad={{ vertical: 'xsmall', horizontal: 'large' }}
                    round="xxsmall"
                    responsive={false}
                  >
                    <Text color="white" size="small" weight="bold">
                      Become a partner
                    </Text>
                  </Box>
                }
                href={`mailto:sales@vendpark.io?${mail.subject}&${mail.body}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

IntegrationPoint.propTypes = {
  isSmall: PropTypes.bool.isRequired,
};

export default IntegrationPoint;
