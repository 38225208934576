import styled from 'styled-components';
import { Box, Image, Main } from 'grommet';

export const headerHeight = '72px';
export const mainHeight = `calc(100vh - ${headerHeight})`;

export const Root = styled(Box)`
  position: relative;
  min-height: 100vh;
`;

export const Header = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${headerHeight};
  z-index: 999;
`;

export const HeaderLogoImg = styled(Image)`
  max-width: ${headerHeight};
`;

export const Content = styled(Main)`
  min-height: ${mainHeight};
`;

export const Inner = styled.div`
  padding-top: ${headerHeight};
`;
