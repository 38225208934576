import { Home, About, Products, Blog } from '../pages';

const routes = [
  {
    label: 'Overview',
    path: '/',
    component: Home,
  },
  {
    label: 'Products',
    path: '/products',
    component: Products,
  },
  {
    label: 'About',
    path: '/about',
    component: About,
  },
  {
    label: 'Blog',
    path: '/blog',
    component: Blog,
  },
];

export default routes;
