import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text, Image, Heading } from 'grommet';
import { includes } from 'rambda';

import AccessImg from 'assets/images/access.svg';
import CarImg from 'assets/images/parking-car.svg';
import MobileImg from 'assets/images/mobile-gateway.png';
import { StyledAccess, CarWrapper } from './Styled';

function Gateway({ size }) {
  const isSmall = includes('small', size);
  return (
    <Box
      background="white"
      pad={{
        horizontal: 'large',
        bottom: 'large',
      }}
    >
      <Box
        width="large"
        margin="auto"
        pad={{ horizontal: isSmall ? 'none' : 'medium' }}
      >
        <Box
          pad={{ bottom: isSmall ? 'small' : 'xxlarge', top: 'none' }}
          direction="row-responsive"
          align="end"
        >
          <Box basis="1/2" pad={{ bottom: isSmall ? 'small' : 'large' }}>
            <Heading level="2" color="black" size="small">
              How it works
            </Heading>
            <Text as="div" lh={2} color="black" size="small">
              Vend installs a simple aftermarket hardware device that can open
              any parking gate seamlessly and securely using Bluetooth,
              eliminating the inconvenience of dealing with paper tickets, QR
              codes, RFID cards, and pay-on-foot stations.
            </Text>
          </Box>
          {!isSmall && (
            <Box
              pad={{ top: 'medium', left: 'xlarge' }}
              basis="1/2"
              align="end"
            >
              <StyledAccess src={AccessImg} alt="" />
            </Box>
          )}
        </Box>
        <Box align="center">
          {isSmall ? (
            <Image src={MobileImg} fill alt="" />
          ) : (
            <CarWrapper>
              <Image src={CarImg} alt="" />
            </CarWrapper>
          )}
        </Box>
      </Box>
    </Box>
  );
}

Gateway.propTypes = {
  size: PropTypes.string.isRequired,
};

export default Gateway;
