import React from 'react';
import { Box } from 'grommet';
import { Link } from 'react-router-dom';

import Logo from 'assets/images/vend-logo.svg';
import Navigation from './Navigation';
import { Header as Wrapper, HeaderLogoImg } from './Styled';

export default function Header() {
  return (
    <Wrapper background="white" responsive>
      <Box
        pad={{
          horizontal: 'xlarge',
        }}
        direction="row"
        align="center"
        justify="between"
        fill
      >
        <Link to="/">
          <HeaderLogoImg src={Logo} alt="" />
        </Link>
        <Navigation />
      </Box>
    </Wrapper>
  );
}
