import React from 'react';
import { Grommet } from 'grommet';

import FirebaseProvider from 'lib/utils/firebase';
import Setup from './Setup';
import theme from './configure/theme';
import GlobalStyle from './configure/theme/GlobalStyle';
import 'sanitize.css/sanitize.css';
import './assets/fonts/gotham_font_face.css';

function App() {
  return (
    <Grommet theme={theme}>
      <GlobalStyle />
      <FirebaseProvider>
        <Setup />
      </FirebaseProvider>
    </Grommet>
  );
}

export default App;
