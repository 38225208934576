import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, ResponsiveContext } from 'grommet';

import getColumns from '../../lib/utils/grid';

function SimpleGrid(props) {
  const { cols, gap, children } = props;
  const size = useContext(ResponsiveContext);
  const columns = useMemo(() => {
    const grid = getColumns(cols);
    return grid[size] || grid.small;
  }, [cols, size]);
  return (
    <Grid gap={gap} columns={columns} rows="auto">
      {children}
    </Grid>
  );
}

SimpleGrid.propTypes = {
  cols: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.array])
  ).isRequired,
  children: PropTypes.node.isRequired,
  gap: PropTypes.string,
};

SimpleGrid.defaultProps = {
  gap: 'medium',
};

export default SimpleGrid;
