export default {
  text: {
    xxxsmall: {
      size: '10px',
      height: '16px',
    },
    xxsmall: {
      size: '12px',
      height: '16px',
    },
    xsmall: {
      size: '14px',
      height: '20px',
    },
    small: {
      size: '16px',
      height: '22px',
    },
    medium: {
      size: '18px',
      height: '24px',
    },
    large: {
      size: '22px',
      height: '28px',
    },
    xlarge: {
      size: '26px',
      height: '32px',
    },
    xxlarge: {
      size: '34px',
      height: '40px',
    },
    extend: props => {
      let extraStyles = '';
      if (props.bold) {
        extraStyles = `
            ${extraStyles}
            font-weight: 700;
          `;
      }
      if (props.lh) {
        extraStyles = `
            ${extraStyles}
            line-height: ${props.lh};
          `;
      }
      if (props.uppercase) {
        extraStyles = `
            ${extraStyles}
            text-transform: uppercase;
          `;
      }
      return `
          ${extraStyles}
        `;
    },
  },
  heading: {
    weight: 900,
    extend: props => {
      let extraStyles = '';
      if (props.weight) {
        extraStyles = `
            ${extraStyles}
            font-weight: ${props.weight};
          `;
      }
      if (props.lh) {
        extraStyles = `
            ${extraStyles}
            line-height: ${props.lh};
          `;
      }
      if (props.fontSize) {
        extraStyles = `
            ${extraStyles}
            font-size: ${props.fontSize};
          `;
      }
      return `
          ${extraStyles}
        `;
    },
  },
};
