import React from 'react';
import { Box, Image } from 'grommet';

import MenuIcon from 'assets/icons/hamburger-icon.svg';
import routes from 'routes/helpers';
import { useCollapsibleMenu } from 'lib/hooks';
import PublicNavBar, {
  Toggler,
  StyledNavLink as NavLink,
} from './Navigation.styled';

export default function Navigation() {
  const [open, setOpen] = useCollapsibleMenu();
  return (
    <Box direction="row" align="center">
      <PublicNavBar as="nav" open={open}>
        {routes.map(({ hide, label, path }) =>
          !hide ? (
            <NavLink
              to={path}
              title={label}
              key={label}
              activeClassName="active"
              exact
            >
              {label}
            </NavLink>
          ) : null
        )}
      </PublicNavBar>
      <Toggler
        plain
        icon={<Image src={MenuIcon} alt="" />}
        onClick={() => setOpen(prev => !prev)}
      />
    </Box>
  );
}
