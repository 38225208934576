import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter, Switch } from 'react-router-dom';

function Routing({ children }) {
  return (
    <BrowserRouter>
      <Switch>{children}</Switch>
    </BrowserRouter>
  );
}

Routing.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Routing;
