import React from 'react';
import { Box, Text, Image, Heading, Anchor } from 'grommet';

import { TriangleCorner, MobileAppWrapper } from './MobApp.styled';

const phones = '/images/phones.png';

const mail = {
  body: `body=Vend team,
  I would like to learn more about the Vend App...`,
  subject: 'subject=Vend App',
};

function MobileApp() {
  return (
    <MobileAppWrapper
      background="white"
      pad={{ horizontal: 'large', top: 'medium', bottom: 'xxlarge' }}
    >
      <Box width="large" margin="auto">
        <Box position="relative" pad={{ top: 'large' }} align="center">
          <TriangleCorner />
          <Heading
            level="2"
            textAlign="center"
            size="small"
            color="black"
            margin={{ vertical: 'none' }}
          >
            Monthly & Transient Parking
          </Heading>
        </Box>

        <Box pad={{ vertical: 'medium' }}>
          <Image src={phones} alt="Mobile app" fill />
        </Box>
        <Box fill align="center">
          <Text
            color="black"
            as="p"
            lh={2}
            size="small"
            textAlign="center"
            margin={{
              top: 'none',
              bottom: 'large',
            }}
          >
            Parking facility owners and operators can choose to display their
            parking locations on the Vend App and transition their monthly and
            transient parkers to digital parking. Vend makes it easy to offer
            program, guest, validations, and more. Alternatively, Vend provides
            tools to integrate seamless parking into your existing branded apps
            though APIs and SDKs.
          </Text>
          <Anchor
            large
            label={
              <Box
                background="#4173DC"
                pad={{ vertical: 'xsmall', horizontal: 'large' }}
                round="medium"
                responsive={false}
              >
                <Text color="white" size="small" weight="bold">
                  Learn more
                </Text>
              </Box>
            }
            href={`mailto:sales@vendpark.io?${mail.subject}&${mail.body}`}
          />
        </Box>
      </Box>
    </MobileAppWrapper>
  );
}

export default MobileApp;
