import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setOpen(prev => prev && !prev);
  }, [pathname, setOpen]);

  return [open, setOpen];
};
