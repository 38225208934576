import React from 'react';
import { Box, Text, Anchor } from 'grommet';

import routes from 'routes/helpers';
import Logo from 'assets/images/vend-logo-white.svg';
import { useResponsive } from 'lib/hooks';
import {
  StyledNavFooterLink as NavLink,
  FooterNavBar,
} from './Navigation.styled';
import { HeaderLogoImg } from './Styled';

const serviceLinks = [
  {
    label: 'Support',
    link: 'mailto:support@vendpark.io?subject=Issue',
  },
  {
    label: 'Contact',
    link: 'mailto:info@vendpark.io',
  },
  {
    label: 'Legal',
    link: '/',
  },
];

export default function Footer() {
  const { isSmall } = useResponsive();
  return (
    <Box
      as="footer"
      align="center"
      pad={{
        top: 'large',
        horizontal: 'large',
      }}
      background="black"
    >
      <Box width="large" margin={{ horizontal: 'auto' }}>
        <HeaderLogoImg
          src={Logo}
          alt="Vend"
          margin={{ horizontal: isSmall ? 'none' : 'auto' }}
        />
        <Box
          fill
          pad={{ top: 'medium' }}
          direction="row"
          align="start"
          justify={isSmall ? 'start' : 'center'}
        >
          <FooterNavBar
            basis="1/2"
            border={
              isSmall
                ? false
                : {
                    size: '1px',
                    color: 'white',
                    side: 'right',
                  }
            }
            direction="row-responsive"
            toEnd
          >
            {routes.map(({ label, path }) => (
              <NavLink
                to={path}
                title={label}
                key={label}
                activeClassName="active"
                exact
              >
                {label}
              </NavLink>
            ))}
          </FooterNavBar>
          <FooterNavBar basis="1/2" direction="row-responsive">
            {serviceLinks.map(({ label, link }, i) =>
              i === 2 ? (
                <NavLink
                  to="/"
                  title={label}
                  key={label}
                  activeClassName="none"
                  exact
                  thin="true"
                >
                  {label}
                </NavLink>
              ) : (
                <Anchor key={label} label={label} href={link} color="white" />
              )
            )}
          </FooterNavBar>
        </Box>
        <Box
          fill
          direction="row-responsive"
          align="center"
          justify="between"
          pad={{ vertical: 'medium' }}
        >
          <Box>
            <Text as="div" color="#747474" weight="bold" size="xsmall">
              &copy; 2020 Vend Park Corporation
            </Text>
          </Box>
          <Box>
            <Text as="div" color="#747474" weight="bold" size="xsmall">
              Privacy Policy · Terms & Conditions
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
