import { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import { includes } from 'rambda';

export default () => {
  const size = useContext(ResponsiveContext);
  const isSmall = includes('small', size);
  const isLarge = includes('large', size);
  return { size, isSmall, isLarge };
};
