import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box, Text, Heading } from 'grommet';

import { IconCard } from 'components/shared';
import { TriangleCorner } from 'components/products/MobApp.styled';
import { useResponsive } from 'lib/hooks';
import { cards, content } from 'lib/constants/aboutContent';

const CoreValues = ({ isSmall }) => (
  <Box>
    <Text
      as="div"
      size="small"
      color="#c8c7c7"
      weight="bold"
      textAlign="center"
      margin={{ bottom: 'xlarge' }}
    >
      CORE VALUES
    </Text>
    <Box
      pad={{ bottom: 'xlarge' }}
      direction="row-responsive"
      justify="between"
      align="start"
    >
      {cards.map(({ index, text, img }) => (
        <IconCard
          key={index}
          text={text}
          textSize="medium"
          imgSrc={img}
          imgWidth={isSmall ? '70px' : 'unset'}
          imgFill={isSmall}
          basis="1/4"
          gap={isSmall ? 'small' : 'medium'}
          margin={{ bottom: 'large' }}
          responsive={false}
          direction={isSmall ? 'row' : 'column'}
          align="center"
          textAlign={isSmall ? 'start' : 'center'}
        />
      ))}
    </Box>
  </Box>
);

CoreValues.propTypes = {
  isSmall: PropTypes.bool.isRequired,
};

export default function About() {
  const { hash } = useLocation();
  const { isSmall } = useResponsive();
  const covidRef = useRef(null);
  useEffect(() => {
    if (covidRef && hash.includes('#covidInfo')) {
      covidRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [covidRef, hash]);
  return (
    <Box animation="fadeIn" background="white" pad={{ horizontal: 'large' }}>
      <Box pad={{ vertical: 'xlarge' }} width="large" margin="auto">
        {content.map(({ label, title, text }, index) => {
          const isOdd = !(index % 2);
          const alignContent = isOdd || isSmall ? 'start' : 'end';
          return (
            <div key={label}>
              {index === 2 ? <CoreValues isSmall={isSmall} /> : null}
              <Box
                margin={{
                  bottom: 'xxlarge',
                }}
                key={label}
                align={alignContent}
                position="relative"
                pad={{
                  top: isSmall ? 'none' : 'medium',
                }}
                {...(index === 2
                  ? {
                      ref: covidRef,
                    }
                  : {})}
              >
                <TriangleCorner reverse={isOdd} />
                <Text as="div" size="small" weight="bold" color="#c8c7c7">
                  {label}
                </Text>
                <Heading
                  level="2"
                  weight={700}
                  fontSize="28px"
                  size="small"
                  color="black"
                  textAlign={alignContent}
                >
                  {title}
                </Heading>
                <Box
                  width={{
                    max: '624px',
                  }}
                >
                  <Text
                    as="div"
                    size="small"
                    color="black"
                    lh={2}
                    textAlign={alignContent}
                  >
                    {text}
                  </Text>
                </Box>
              </Box>
            </div>
          );
        })}
      </Box>
    </Box>
  );
}
