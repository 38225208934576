import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Text, Image, Heading, Button } from 'grommet';
import { CircleInformation } from 'grommet-icons';

import Img from 'assets/images/car-bluetooth.svg';
import { CovidWrapper } from './Styled';

export default function CovidMessage() {
  const history = useHistory();
  return (
    <CovidWrapper>
      <Box direction="row-responsive" align="center" justify="between">
        <Box direction="row" align="center" gap="small">
          <Image src={Img} alt="" />
          <Box>
            <Heading color="black" as="p" level={2} size="small" margin="none">
              COVID-19 parking relief
            </Heading>
            <Text color="black" size="small" weight="bold">
              Be safe, go contactless
            </Text>
          </Box>
        </Box>
        <Box pad={{ vertical: 'xsmall' }} align="end">
          <Button
            plain
            reverse
            gap="xsmall"
            icon={<CircleInformation color="white" />}
            label={
              <Text size="small" color="white" weight="bold">
                Learn more
              </Text>
            }
            onClick={() => history.push('/about#covidInfo')}
          />
        </Box>
      </Box>
    </CovidWrapper>
  );
}
