import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Box, Button } from 'grommet';

import { mq } from 'configure/theme';
import { headerHeight, mainHeight } from './Styled';

export const Toggler = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  font-size: 1.125rem;
  color: #000;
  text-decoration: none;
  transform: translateY(-2px);
  ${mq[2]} {
    display: none;
  }
`;

export const SigningHolder = styled(Box)`
  ${mq[2]} {
    border-left: 2px solid #d4d4d4;
  }
`;

export const StyledNavLink = styled(NavLink)`
  display: block;
  position: relative;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  color: #bababa;
  transition-property: color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  margin: 0px;
  text-decoration: none;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  &:hover {
    color: #919191;
  }
  &.active {
    color: #000;
  }
`;

const NavBar = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: ${headerHeight};
  height: ${props => (props.open ? mainHeight : 0)};
  overflow: hidden;
  transition-property: height;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  z-index: 999;
  ${mq[2]} {
    flex-direction: row;
    position: static;
    background-color: transparent;
    height: auto;
    opacity: 1;
    left: unset;
    top: unset;
    right: unset;
  }
`;

export const FooterNavBar = styled(Box)`
  display: flex;
  align-items: flex-start;
  ${props => (props.toEnd ? 'padding-right' : 'padding-left')}: 16px;
  ${mq[1]} {
    padding-left: 0;
    padding-right: 0;
    align-items: ${props => (props.toEnd ? 'flex-end' : 'flex-start')};
    justify-content: space-evenly;
  }
`;

export const StyledNavFooterLink = styled(NavLink)`
  display: block;
  position: relative;
  font-size: 1rem;
  font-weight: ${props => (props.thin ? 'normal' : 'bold')};
  line-height: 1;
  color: #fff;
  transition-property: color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  margin: 0px;
  padding: 4px 0;
  text-decoration: none;
  &:hover {
    color: #bababa;
  }
  &.active {
    color: #bababa;
  }
`;

export default NavBar;
