import React from 'react';
import { Box, Image, Text } from 'grommet';

import { IconCard } from 'components/shared';

import Icon0 from 'assets/icons/sdk/sdk-0.svg';
import Icon1 from 'assets/icons/sdk/sdk-1.svg';
import Icon2 from 'assets/icons/sdk/sdk-2.svg';
import Icon3 from 'assets/icons/sdk/sdk-3.svg';
import Icon4 from 'assets/icons/sdk/sdk-4.svg';
import Icon5 from 'assets/icons/sdk/sdk-5.svg';
import Logo from 'assets/icons/sdk/sdk-logo.svg';
import {
  SchemeNote,
  Connector,
  IconsHolder,
  SchemeIconLabel,
} from './IntegrationPoint.styled';

const cards = [
  {
    index: 1,
    img: Icon1,
    text: 'Parking Aggregators',
  },
  {
    index: 2,
    img: Icon3,
    text: 'Rideshare & Rentals',
  },

  {
    index: 3,
    img: Icon4,
    text: 'Vehicle\nOEMS',
  },

  {
    index: 4,
    img: Icon2,
    text: 'Mapping & Navigation',
  },
  {
    index: 5,
    img: Icon0,
    text: 'Monthly & Transient',
  },
];

export default function IntegrationScheme() {
  return (
    <IconsHolder position="relative">
      <Box
        fill
        background="light-7"
        round="52px"
        position="relative"
        direction="row"
        justify="evenly"
        wrap
        pad={{
          top: 'xsmall',
          bottom: 'small',
          horizontal: 'xsmall',
        }}
      >
        {cards.map(({ index, text, img }) => (
          <IconCard
            key={index}
            text={text}
            imgSrc={img}
            imgFill
            margin={{ bottom: 'none' }}
            responsive={false}
            pad="xsmall"
            gap="xsmall"
            textSize="xxsmall"
            className="info-card"
          />
        ))}
      </Box>
      <SchemeNote width="medium" className="note">
        <Box
          direction="row-responsive"
          gap="xsmall"
          align="center"
          justify="between"
        >
          <Connector />
          <Box width={{ max: '72px', min: '72px' }} alignSelf="center">
            <Image src={Logo} alt="" fill />
          </Box>
          <Connector both />
          <Box
            position="relative"
            width={{ max: '72px', min: '72px' }}
            alignSelf="center"
          >
            <Box>
              <Image src={Icon5} alt="" fill />
            </Box>
            <SchemeIconLabel pad="small">
              <Text
                color="black"
                weight="bold"
                size="xxsmall"
                wordBreak="keep-all"
              >
                Parking Facility
              </Text>
            </SchemeIconLabel>
          </Box>
        </Box>
      </SchemeNote>
    </IconsHolder>
  );
}
