import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Layout from 'layout/Layout';
import routes from './helpers';

function Public({ ...props }) {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, label, component }) => (
          <Route exact path={path} key={label} component={component} />
        ))}
        <Route
          {...props}
          key={-1}
          render={() => (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          )}
        />
      </Switch>
    </Layout>
  );
}

export default Public;
