import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import styled from 'styled-components';

const Wrapper = styled(Box)`
  width: 100%;
  height: 0;
  padding-bottom: ${props => props.aspect * 100}%;
  position: relative;
  background-image: url(${props => props.imgSrc});
  background-size: cover;
  background-position-x: ${props => props.bgXPosition};
  background-position-y: ${props => props.bgYPosition};
  & > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

function AspectBox(props) {
  const { overflow, aspect, children, ...rest } = props;
  return (
    <Wrapper aspect={aspect} {...rest} overflow={overflow}>
      <div>{children}</div>
    </Wrapper>
  );
}

AspectBox.propTypes = {
  aspect: PropTypes.number.isRequired,
  children: PropTypes.node,
  overflow: PropTypes.string,
  bgYPosition: PropTypes.string,
  bgXPosition: PropTypes.string,
};

AspectBox.defaultProps = {
  children: null,
  overflow: 'hidden',
  bgXPosition: 'center',
  bgYPosition: 'center',
};

export default AspectBox;
